import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import lsrImg from '../../assets/img/pages/vr-games/VIRSAT-Life-Saving-Rules-Aware.jpg'
import vrTrainingImg from '../../assets/img/pages/articles/Is VR training only for a niche market and are VR headsets costly.jpg'
import empty from '../../assets/img/icons/empty.png'
import ogImg from '../../assets/img/pages/articles/5 Reasons Why Companies Should Consider.jpg'

const FiveReasons = ({location}) => {
  const [pageTitle] = useState('5 Reasons Why Companies Should Consider VR as a Learning Tool')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Layout>
        <Seo
          title={pageTitle}
          description='According to PWC, “Employers are facing dilemma: Their workforce needs to learn new skills, upgrade existing capabilities, or complete compliance training.'
          image={ogImg}
          ogType='article'
        />
        <PageBanner title='Articles' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>5 Reasons Why Companies Should Consider VR as a Learning Tool</strong>
                </h1>
                <div className='author-date'>
                  By <strong>Willem Peuscher</strong>
                  <span className='spacer'>|</span>October 05, 2021
                  <span className='spacer'>|</span>4 minute read
                </div>
              </header>
              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)} 
                aria-hidden className='article-main-image no-zoom'>
                  <StaticImage
                    src='../../assets/img/pages/articles/5 Reasons Why Companies Should Consider.jpg'
                    alt='5 Reasons Why Companies Should Consider VR as a Learning Tool'
                    title='5 Reasons Why Companies Should Consider VR as a Learning Tool'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>
              <p className="justify">
              According to PWC <Link to="#link-group"><sup>[1]</sup></Link>, “Employers are facing a dilemma: Their workforce needs to learn new skills, upgrade existing capabilities, or complete compliance training, but may not be able to do so ‘in person’ given the current environment.”. 
              </p>
              <p className="justify no-mar-bottom">
                Yet, training is especially important when workers get less practical experience due to limitations in interpersonal contacts. So how can employers deal with the challenge? Virtual Reality (VR) training helps to address the challenges and is developing rapidly as an accelerated learning technique. As companies gear up to use VR, it is important to discuss a number of common unknown facts and questions about VR.
              </p>
              <h2 className="main-subtitle no-mar-bottom">Question #1:</h2>
              <h3 className="main-subtitle dark no-mar-top">Is VR training only for a niche market and are VR headsets costly?</h3>
              <div className="answer-box">
                <h3 className="main-subtitle large center">Answer #1</h3>
                <p className='justify'>Up to 2019, VR used to be only for experts with costly and powerful PC and a studio to set up a VR environment. However, since 2019 VR  training has moved quickly from a niche market to a cost effective tool that can be used to train many people. as advanced VR headset became available with current prices ranging from $400-$700 per headset. These headsets work “stand alone”, without the use of a PC or internet. Typical trademarks are Oculus Quest and Pico Neo series.
                </p>
              </div>

              <div 
                onClick={()=>handeleActiveImg(vrTrainingImg)} 
                aria-hidden className='article-image'>
                <StaticImage
                  src='../../assets/img/pages/articles/Is VR training only for a niche market and are VR headsets costly.jpg'
                  alt='Is VR training only for a niche market and are VR headsets costly?'
                  title='Is VR training only for a niche market and are VR headsets costly?'
                  quality="100"
                  className="article-gatsby-image"
                />
              </div>

              <h2 className="main-subtitle no-mar-bottom">Question #2:</h2>
              <h3 className="main-subtitle dark no-mar-top">Is VR training only for a few people, like professionals or experts?</h3>
              <div className="answer-box no-mar-bottom">
                <h4 className="main-subtitle large center">Answer #2</h4>
                <p className='justify'>VR headsets are used heavily in the consumer market for gamers. Many people are now buying these headsets and play games. Also the professional market with serious games is developing rapidly. E.g. the Oculus Quest is selling millions of headsets to both consumer and business markets. Many people are now becoming accustomed to VR Headsets. They appreciate the ability to learn complex matters with smart games. Everybody, young or old, practical worker or office-based, can enjoy VR games and learn new skills.</p>
              </div>

              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)}
                aria-hidden className='article-image no-zoom'>
                <StaticImage
                  src='../../assets/img/pages/articles/Is VR training only for a few people, like professionals or experts.jpg'
                  alt='Is VR training only for a few people, like professionals or experts'
                  title='Is VR training only for a few people, like professionals or experts'
                  quality="100"
                  className="article-gatsby-image"
                />
              </div>

              <h2 className="main-subtitle no-mar-bottom">Question #3:</h2>
              <h3 className="main-subtitle dark no-mar-top">What are the costs of VR training compared to classroom training?</h3>
              <div className="answer-box no-mar-bottom">
                <h4 className="main-subtitle large center">Answer #3</h4>
                <p className='justify'>There are many direct and indirect costs which impact the overall cost of a training. For classroom trainings, the direct costs of the lecturer and classroom might be of importance, but the indirect costs of “time away from work” and “travel expenses”, might be even more impacting the overall costs.</p>
                <p className='justify'>
                VR headsets offer the opportunity to deliver the training in various ways: train at the trainee’s worksite or at home. In fact, VR brings a private interactive cinema to the trainee. There are no lecturer costs. Initially, there might be a steward required to facilitate the logistics of the VR. Depending on the game/logistics, with one VR headset, employers can easily train 200-400 people in a month (e.g., half an hour one trainee).
                </p>
              </div>

              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)}
                aria-hidden className='article-image no-zoom'>
                <StaticImage
                  src='../../assets/img/pages/articles/What are the costs of VR training compared to classroom training.jpg'
                  alt='What are the costs of VR training compared to classroom training'
                  title='What are the costs of VR training compared to classroom training'
                  quality="100"
                  className="article-gatsby-image"
                />
              </div>

              <h2 className="main-subtitle no-mar-bottom">Question #4:</h2>
              <h3 className="main-subtitle dark no-mar-top">What are the benefits of VR over e-learning?</h3>
              <div className="answer-box no-mar-bottom">
                <h4 className="main-subtitle large center">Answer #4</h4>
                <p className='justify'>In industry, e-learning is often referred to as the services via a website which teaches and helps students to improve in certain subjects. Research demonstrated <Link className='secondary-color' to="#link-group"><sup>[2]</sup></Link> that VR, in comparison with e-learning, gives the trainees:</p>
                <ul className='secondary-color font-bold add-mar-left'>
                  <li>35% More confidence improvement to apply the course material</li>
                  <li>2-3 Times more emotional connectiveness to the course material</li>
                  <li>4 Times More Focus</li>
                </ul>
                <p className='justify'>
                This can be explained because VR provides the ability to practice in an immersive, low-stress environment, where less interruptions and distractions are occurring.
                </p>
              </div>

              <h2 className="main-subtitle no-mar-bottom">Question #5:</h2>
              <h3 className="main-subtitle dark no-mar-top">How can you monitor the progress of a VR gamer?</h3>
              <div className="answer-box no-mar-bottom">
                <h4 className="main-subtitle large center">Answer #5</h4>
                <p className='justify'>The trainee’s learning results can be stored in the Learning Management System.  The VR headset has sensors and can monitor many aspects, like speed of reaction, outcome of tests, gamer’s eye position, hand positions, etc. Through these sensors many game aspects can be monitored – if required. This helps the trainee to understand his learning improvements, proficiency levels, etc.</p>
              </div>

              <div>
                <ul className="links-anchors" id="link-group">
                  <li>
                    <a href="https://www.pwc.com/us/en/services/consulting/technology/emerging-technology/vr-study-2020.html">[1] https://www.pwc.com/us/en/services/consulting/technology/emerging-technology/vr-study-2020.html</a>
                  </li>
                  <li>
                    <a href="https://www.pwc.com/us/en/tech-effect/emerging-tech/virtual-reality-study.html">[2] https://www.pwc.com/us/en/tech-effect/emerging-tech/virtual-reality-study.html</a>
                  </li>
                </ul>
              </div>

              <div className="footer-note primary-bg">
                <div>
                  <p className='no-mar'>
                  If you want to learn more, see the brochure - <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware-Knowledge</a>, or write to <a href="mailto:info@virsat.com">info@virsat.com</a>. 
                  </p>
                </div>
                <div>
                  <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf"><img src={lsrImg} alt='VIRSAT Life Saving Rules Aware'/></a>
                </div>
              </div>

              {/* <div className="subscribe-container">
                <p>To subscribe to the newsletter, visit <Link to='/lp/newsletter/'>https://virsat.com/lp/newsletter/</Link>.</p>
                <Link className="button-link secondary" to="/lp/newsletter">Subscibe Me</Link>
              </div> */}
              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition}/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default FiveReasons
